import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import LatteUI from "@bybas/latte-ui";

Vue.config.productionTip = false;
Vue.use(LatteUI);

import "@mdi/font/scss/materialdesignicons.scss";
import "particles.js/particles";

import "./assets/scss/global.scss";
import "./registerServiceWorker";

new Vue({
	router,
	render: h => h(App)
}).$mount("#app");
